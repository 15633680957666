import { UPDATE_THEME } from "./HomeConstants";

const HomeReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case UPDATE_THEME:
      return {
        ...state,
        theme: payload,
      };
    default:
      return state;
  }
};

export default HomeReducer;
