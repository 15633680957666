import React, { useReducer } from "react";
import HomeReducer from "./HomeReducer";
import HomeContext from "./HomeContext";
import { updateTheme } from "./HomeActions";

const HomeState = (props) => {
  const initialState = {
    theme: "dark",
  };

  const [state, dispatch] = useReducer(HomeReducer, initialState);

  const toggleTheme = (theme) => {
    dispatch(updateTheme(theme));
  };

  const contextValue = {
    ...state,
    toggleTheme,
  };

  return (
    <HomeContext.Provider value={contextValue}>
      {props.children}
    </HomeContext.Provider>
  );
};

export default HomeState;
